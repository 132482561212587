// @flow

import Honeybadger from '@honeybadger-io/js';
import type { JSONValue } from 'common/types';

const failureStack: Array<string> = [];
let usingFailureStack = false;
// Wrap root-level extraction with this higher order function to get neat error
// handling behavior
export function withFailureHandler<V>(extract: JSONValue => V): JSONValue => V {
  return json => {
    // Allow `failure` extractor to customize its logic based on whether the
    // extraction has been wrapped with this function or not.
    usingFailureStack = true;
    try {
      const returnValue = extract(json);
      // Now `failureStack` should be populated if any errors happened when extracting.
      //
      // Do not handle this on the server side, though, as we get better error
      // messages on browser side and debugging is also easier in the browser.
      // Failures will be handled again when React mounts, and debugging extractor
      // failures on browser-side is waaay easier than debugging an error
      // raised when rendering a component on the server side.
      if (failureStack.length > 0 && typeof document !== 'undefined') {
        // Make a deep clone of the failure stack to ensure that the
        // failureStack doesn't get modified from under us
        const snapshotStack = JSON.parse(JSON.stringify(failureStack));

        const error = new Error('Extraction error');

        if (__DEV__) {
          // Without the setTimeout, the console log can fail for some unknown reason
          /* eslint-disable no-alert, no-console */
          setTimeout(() => {
            console.group('Extraction error');
            console.error(snapshotStack.join('\n* '), {
              json
            });
            console.trace();
            console.groupEnd();
          }, 0);

          alert('Extraction error! Check console for more information');
          /* eslint-enable no-alert, no-console */
        } else {
          Honeybadger.notify(error, {
            message: failureStack.join(', '),
            context: { json }
          });
        }
      }
      return returnValue;
    } catch (e) {
      // Any errors that get here are result of real runtime errors, and we should
      // not handle them.
      throw e;
    } finally {
      // Reset the state regardless if there was an error or not
      usingFailureStack = false;
      failureStack.length = 0;
    }
  };
}

export function failure<V>(errorMessage: string, fallback: () => V): V {
  if (!usingFailureStack) {
    // This situation means that the extraction pipeline for this particular case
    // has not been wrapped with the failure handler function.
    //
    // In that case, handle the failures as before.
    const error = new Error(errorMessage);
    if (__DEV__) {
      throw error;
    } else {
      Honeybadger.notify(error);
      return fallback();
    }
  }
  // This extraction pipeline has been wrapped with a failure handling pipeline.
  // The failure handler will handle all errors received during extraction.
  failureStack.push(errorMessage);
  return fallback();
}
